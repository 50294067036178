.privacy-policy{
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-h h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}

.privacy-h{
    
    background-color: #fecc00;
    padding: 20px;
}

.privacy-h p{
    text-align: justify;
}

@media (max-width: 320px){
    .privacy-h h1{
        font-size: 30px;
    }
}