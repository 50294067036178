a {
  text-decoration: none;
}

li {
  list-style: none;
}

.main-nav {
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: #fecc00;
}

.logo img{
  height: 150px;
  margin-left: 20px;
}



.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}





/* ----------- Grid part ends ----------------- */

.dropbtn {
  color: #01184e;
  padding: 10px;
  margin: 0px 20px;
  font-size: 20px;
  border: none;
  background: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown a{
  font-size: 1.5rem;
  font-weight: 500;
}

.dropdown-content {
  display: none;
  position: absolute;
  padding: 10px;
  border-radius: 20px;
  background-color: #fecc00;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}

.dropdown-content a {
  color: #fecb00;
  font-size: 20px;
  padding: 10px 10px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #01184e;
  color: #fecc00;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.dropdown:hover .dropbtn {
  background-color: #01184e;
  color: #fecb00;
  border-radius: 10px;
}

.menu-link ul li {
  font-size: 1.5rem;
  font-weight: 500;
}

.menu-link ul li a {
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  margin: 0px 10px;
  padding: 10px;
}
.menu-link ul li:hover > a {
  color: #fecc00;
  background-color: #01184e;
  transition: 0.5s;
  padding: 10px;
}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: #fecc00;
    height: 25rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
    cursor: pointer;
  }
}

@media (max-width: 1050px) {
  .logo img{
    height: 100px;
    margin-top: 15px;
  }
}

@media (max-width: 1000px) {
  .logo img{
    margin-top: 5px;
  }
}



@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }
  .logo img{
    height: 70px;
    margin-top: 15px;
  }

  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}