.papers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.p-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.p-img img{
    height: 400px;
    margin-right: 40px;
    border-radius: 20px;
    border: 2px solid #fecc00;
}

.samplepapers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-right: 50px;
}

.papers h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: #fecc00;
    height: 150px;
    width: 100%;
    color: #01184e;
    font-family: 'Fredoka One', cursive;
}

.s-papers{
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecc00;
    color: #01184e;
    height: 160px;
    width: 300px;
    margin: 10px 10px;
    border-radius: 20px;
    border: 2px solid #000000;
    text-align: center;
}

.p-cont a{
    text-decoration: underline;
}



.p-cont a:hover{
    transform: scale(1.10);
}

.p-rec{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1140px) {
    .p-img img{
        height: 250px;
    }
}

@media screen and (max-width: 1000px) {
    .p-img{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .p-cont{
        width: 200px;
    }
}

@media screen and (max-width: 500px) {
    .p-cont{
        width: 160px;
    }
    .p-cont h2, a{
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    
    .p-rec{
        grid-template-columns: 1fr;
    }
}