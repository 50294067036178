.spot{
    display: flex;
    justify-content: center;
    align-items: center;
}

.s-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 20px 0px;
}

.s-left img{
    height: 700px;
    width: 500px;
}

.s-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.spot-desc{
    color: #01184e;
    background-color: #fecc00;
    margin-bottom: 50px;
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px 20px 0px 0px ;
}

.s-right a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.s-right a:last-child{
    margin-top: 20px;
    text-decoration: underline;
    color: #fecc00;
    text-decoration: none;
    margin-bottom: 20px;
    display: flex;
    border: 1px solid #fecc00;
    padding: 10px;
    border-radius: 5px;
}

.s-right a:last-child:hover{
    background-color: #fecc00;
    border: 1px solid #fecc00;
    color: #01184e;
}

.s-links{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.s-btn{
    background-color: #fecc00;
    margin: 5px;
    font-size: 48px;
    color: #01184e;
    border: 2px solid #fecc00;
    padding: 10px 20px;
    border-radius: 0px 10px 0px 10px;
}
.s-btn1{
    background-color: #01184e;
    margin: 10px;
    font-size: 48px;
    color: #fecc00;
    border: 2px solid #fecc00;
    padding: 10px 20px;
    border-radius: 10px 0px 10px 0px;
}

.s-btn:hover{
    background-color: #01184e;
    border: 2px solid #fecc00;
    color: #fecc00;
}
.s-btn1:hover{
    background-color: #fecc00;
    border: 2px solid #01184e;
    color: #01184e;
}

@media (max-width: 1060px) {
    .spot{
        flex-direction: column;
    }
}
@media (max-width: 600px) {
    .s-left img{
        height: 500px;
        width: 300px;
    }
}
@media (max-width: 370px) {
    .s-left img{
        height: 400px;
        width: 250px;
    }
    .s-links a{
        flex-direction: column;
    }
    
}