.maylike-products-wrapper-two{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}

.maylike-products-container-two{
    display: flex;
    justify-content: center;
    gap: 15px;
}
.maylike-products-container-two img{
    height: 600px;
}

.marquee-two {
    position: relative;
    height: 700px;
    width: 100%;
    overflow-x: hidden;
}
  
.track-two {  
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 15s linear infinite;
    width: 180%;
}
  
@keyframes marquee-two {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}










.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    height: 550px;
    z-index: -99;
}

.h-img{
    background-color: #fecc00;
    border-radius: 50% 50% 50% 50% / 50% 50% 20% 20% ;
    margin-right: 20px;
}

.h-img-left{
    background-color: #fecc00;
    border-radius: 50% 50% 50% 50% / 50% 50% 20% 20% ;
    margin-left: 20px;
}

.h-img img{
    height: 450px;
}
.h-img-left img{
    height: 450px;
}

.h-cont{
    background-color: #fecc00;
    border-radius: 20px;
    padding: 0px 30px;
}


.h-cont h1{
    color: #01184e;
    font-size: 60px;
    font-family: 'Roboto Mono', monospace;
}


.maylike-products-wrapper{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}
  
.maylike-products-wrapper h2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    font-size: 60px;
    background-color: #fecc00;
    height: 150px;
    width: 100%;
    color: #01184e;
    font-family: 'Fredoka One', cursive;
}
  
.maylike-products-container{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}
.maylike-products-container img{
    height: 300px;
}

.marquee {
    position: relative;
    height: 400px;
    width: 100%;
    overflow-x: hidden;
}
  
.track {  
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 15s linear infinite;
    width: 180%;
}
  
.track:hover {
    animation-play-state: paused;
}
  
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}


.who{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

.who-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 1;
    border: 2px solid #01184e;
    height: 500px;
    margin: 0px 20px;
    border-radius: 100px;
    background: linear-gradient(to right, rgb(233, 227, 111), #feb600c1);
}

.who-content p{
    text-align: justify;
    width: 420px;
    font-weight: 500;
    font-size: 17px;
}

.w-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.w-img img{
    height: 450px;
    border-radius: 100%;
    border: 2px solid #fecc00;
}



.whyus{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 850px;
    margin: 20px 0px;
}

.c-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.c-img img{
    height: 400px;
    border-radius: 20px;
    margin-left: 20px;
    border: 2px solid #fecc00;
}

.services{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-right: 50px;
}

.s-heading h1{
    font-size: 50px;
}

.services h1{
    color: #fecc00;
}

.w-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecb00e1;
    color: #01184e;
    height: 160px;
    width: 160px;
    margin: 10px 10px;
    border-radius: 20px;
    border: 2px solid #000000;
    transition: transform 0.2s;
    text-align: center;
}

.w-cont:hover{
    transform: scale(1.03);
    background-color: #fecc00;
}

.w-cont img{
    height: 80px;
    width: 80px;
}

.w-rec{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.f-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.f-left h1{
    font-size: 50px;
    color: #fecc00;
}

.f-left img{
    height: 500px;
}

.f-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding: 0px 10px;
    background-color: #fecc00;
    border-radius: 20px;
}

.f-form{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
}

.f-form input{
    height: 40px;
    width: 60%;
    border: none;
    background: none;
    border-radius: 10px 0px;
    margin: 10px 0px;
    border-bottom: 2px solid #01184e;
    outline: none;
}

.f-form textarea{
    background: none;
    outline: none;
    border: none;
    width: 60%;
    border-radius: 10px 0px;
    border-bottom: 2px solid #01184e;
}

.f-form button{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: #01184e;
    color: #fecc00;
    border: 2px solid #01184e;
}

.f-form button:hover{
    cursor: pointer;
    padding: 10px;
    background-color: #fecc00;
    color: #01184e;
}



@media screen and (max-width: 1140px) {
    .header img {
        height: 300px;
    }
    .w-cont{
        height: 100px;
        width: 100px;
    }
    .w-cont img{
        height: 40px;
        width: 40px;
    }
    .w-img img{
        height: 400px;
    }
    .h-cont h1{
        font-size: 50px;
    }
    .w-img img{
        height: 350px;
    }
    .w-cont span{
        font-size: 13px;
    }
}

@media screen and (max-width: 1000px) {
    .who-content{
        height: 450px;
    }
    .w-img img{
        height: 300px;
    }
    .who-content p{
        font-size: 15px;
        width: 350px;
    }
    .c-img img{
        height: 250px;
    }
    .h-cont h1{
        font-size: 40px;
    }
    .h-img img{
        height: 240px;
    }
    .h-img-left img{
        height: 240px;
    }
}

@media screen and (max-width: 800px) {
    .w-img{
        display: none;
    }

    .header{
        margin-top: 10px;
    }

    .h-cont h1{
        font-size: 20px;
    }

    .c-img img{
        height: 160px;
    }

    .h-img img{
        height: 200px;
    }
    .h-img-left img{
        height: 200px;
    }
    .f-left{
        display: none;
    }
    .who-content{
        height: 530px;
    }
    .who-content p{
        width: 300px;
    }
}

@media screen and (max-width: 600px) {
    .w-rec{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .header{
        flex-direction: column;
        margin-top: 10px;
    }
    .h-cont{
        margin: 20px 0px;
    }
    .who{
        margin-top: 130px;
    }

    .whyus{
        flex-direction: column;
        margin: 0px;
    }
    .c-img img{
        height: 240px;
    }
}

@media screen and (max-width: 400px) {
    .w-rec{
        grid-template-columns: 1fr 1fr;
    }
    .c-img{
        display: none;
    }
    .who-content h1{
        font-size: 20px;
    }
    .who-content p{
        width: 200px;
    }
    .who-content{
        height: 770px;
    }
}

.testimonial{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.testimonial video{
    width: 70%;
    height: 100%;
    object-fit: cover;
}

.app_video-overlay{
    position: absolute;
    inset: 0;
    background: rgb(0,0,0,0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.app_video-overlay_circle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #01184e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
