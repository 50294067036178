.c-footer{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #fecc00;
    color: #01184e;
}

.f-about, .f-results, .f-courses, .more {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.f-logo img{
    height: 100px;
    padding-top: 10px;
}

.f-about a, .f-results a, .f-courses a, .more a{
    margin: 15px 0px;
    padding-bottom: 5px;
    font-size: 20px;
    color: #01184e;
}

.f-about a:hover, .f-results a:hover, .f-courses a:hover, .more a:hover{
    border-bottom: 2px solid #01184e;
    padding-bottom: 3px;
}

.address {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.address img{
    height: 50px;
}

.f-privacy{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #fecc00;
    padding-bottom: 5px;
}

.privacy a{
    text-decoration: none;
    color: #01184e;
}

.privacy a:hover{
    text-decoration: underline;
}

.terms a{
    text-decoration: none;
    color: #01184e;
}

.terms a:hover{
    text-decoration: underline;
}

.designer a{
    text-decoration: none;
    color: #01184e;
}

.designer a:hover{
    text-decoration: underline  ;
}

.f-refund a{
    text-decoration: none;
    color: #01184e;
}

.f-refund a:hover{
    text-decoration: underline;
}

.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-footer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fecc00;
    padding: 10px;
}

.line{
    height: 2px;
    width: 90%;
    background-color: #01184e;
}







@media screen and (max-width: 800px) {
    .f-about{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .f-courses{
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .f-logo, .f-results, .more {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .c-footer{
        flex-direction: column;
        align-items: center;
    }
    .f-privacy{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 350px) {
    .copyright{
        font-size: 12px;
    }
    .designer{
        font-size: 12px;
    }
    .designer a{
        font-size: 12px;
    }
}
