.junior{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #01184e;
}

.junior h1{
    font-size: 50px;
    color: #fecc00;
}
.st-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.st-img img{
    height: 600px;
}

.j-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.st-junior{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: justify;
    flex: 1;
}

.s-junior{
    background-color: #fecb00e1;
    padding: 8px;
    margin: 10px;
    border-radius: 10px;
    height: 250px;
    width: 400px;
    transition: transform 0.2s;
}

.s-junior:hover{
    transform: scale(1.03);
    background-color: #fecb00;
}

.s-junior h2{
    font-size: 25px;
}

.junior span{
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .s-junior{
        width: 300px;
    }
}

@media screen and (max-width: 600px) {
    .st-junior{
        display: grid;
        grid-template-columns: 1fr;
    }
    .st-img{
        display: none;}
}
