.jee{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.jee h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: #fecc00;
    height: 150px;
    width: 100%;
    color: #01184e;
    font-family: 'Fredoka One', cursive;
}

.j-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

.jee h2{
    font-size: 60px;
    color: #fecc00;   
}

.j-recs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    
}

.j-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecb00e1;
    color: #01184e;
    height: 330px;
    width: 330px;
    margin: 10px 10px;
    border-radius: 20px;
    border: 2px solid #01184e;
    transition: transform 0.2s;
}

.j-cont:hover{
    transform: scale(1.03);
    background-color: #fecc00;
}

.j-cont img{
    height: 200px;
    border: 1px solid #fecc00;
    border-radius: 25px;
}
