.vision{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.vision h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: #fecc00;
    height: 150px;
    width: 100%;
    color: #01184e;
    font-family: 'Fredoka One', cursive;
}

.v-rec{
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-vision {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    border: 2px solid #01184e;
    height: 400px;
    width: 800px;
    margin: 0px 20px;
    border-radius: 100px;
    background: linear-gradient(to right, rgb(233, 227, 111), #feb600c1);
}

.h-vision p{
    font-size: 30px;
    font-weight: bold;
    margin: 0px 10px;
    text-align: justify;
}

.v-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.v-img img{
    height: 500px;
}

@media screen and (max-width: 1140px) {
    .v-img img{
        height: 270px;
    }
}

@media screen and (max-width: 1000px) {
    .h-vision{
        height: 300px;
        width: 600px;
    }
    .h-vision p{
        font-size: 20px;
    }
    .v-img img{
        height: 200px;
    }
}

@media screen and (max-width: 800px) {
    .v-img{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .h-vision{
        height: 200px;
        width: 400px;
    }
    .h-vision p{
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .h-vision{
        height: 150px;
        width: 350px;
    }
    .h-vision p{
        font-size: 15px;
    }
}