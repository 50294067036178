.t-and-c{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecc00;
    padding: 10px;
    
}

.t-and-c h1{
    font-size: 50px;
}

.t-and-c p{
    text-align: justify;

}

@media (max-width: 320px){
    .t-and-c h1{
        font-size: 30px;
    }
}