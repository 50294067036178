.about-us{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecc00;
}
.a-heading{
    font-size: 60px;
    font-family: 'Fredoka One', cursive;
    color: #01184e;
    font-weight: 700;
    margin-top: 20px;
}

.a-subheading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: 100px;
}

.a-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.history{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.d-msg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 2px solid #01184e;
    height: 450px;
    width: 700px;
    margin: 0px 20px;
    border-radius: 100px;
    background: linear-gradient(to right, rgb(233, 227, 111), #feb600c1);
}

.d-msg p{
    text-align: justify;
    font-weight: 500;
    width: 500px;
    font-size: 17px;
}

.d-img img{
    height: 450px;
    border-radius: 100px;
}

.a-img img{
    height: 350px;
}

.about-us h2{
    font-size: 60px;
    font-family: 'Abril Fatface', cursive;
    color: #01184e;
}

@media screen and (max-width: 650px) {
    .a-img img{
        display: none;
    }
}

@media screen and (max-width: 500px) {
.d-msg p{
    font-size: 12px;
}
.d-msg {
    height: 350px;
    width: 650px;
}
}

@media screen and (max-width: 350px) {
    
}