.social{
    display: flex;
    justify-content: center;
    align-items: center;
}

.social a{
    text-decoration: none;
    
    color: #01184e;
    margin: 10px 10px;
    font-size: 35px;
}

.social a:hover{
    color: #4267B2;
    border: none;
}

.social a:last-child:hover{
    color:  #E1306C;
    border: none;
}