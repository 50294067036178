.commerce{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #01184e;
}

.commerce h1{
    font-size: 50px;
    color: #fecc00;
}

.c-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.co-img img{
    height: 500px;
}

.c-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sen-commerce{
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
}

.s-commerce{
    background-color: #fecb00e1;
    padding: 8px;
    margin: 10px;
    border-radius: 10px;
    height: 250px;
    width: 400px;
    transition: transform 0.2s;
}

.s-commerce:hover{
    transform: scale(1.03);
    background-color: #fecc00;
}

.s-commerce h2{
    font-size: 25px;
}

@media screen and (max-width: 1140px) {
    .sen-commerce{
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 950px) {
    .co-img img{
        height: 400px;
    }
}

@media screen and (max-width: 800px) {
    .co-img img{
        height: 300px;
    }
}

@media screen and (max-width: 700px) {
    .co-img{
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .s-commerce{
        width: 300px;
    }
}

@media screen and (max-width: 350px) {
    .s-commerce{
        width: 250px;
    }
}