.foundation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.foundation h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    background-color: #fecc00;
    height: 300px;
    width: 100%;
    color: #01184e;
    font-family: 'Fredoka One', cursive;
}

.foundation img{
    height: 1200px;
    border: #fecc00 2px solid;
}