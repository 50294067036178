.teachers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.teachers h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    background-color: #01184e;
    height: 300px;
    width: 1500px;
    color: #fecc00;
    font-family: 'Fredoka One', cursive;
}