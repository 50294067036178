.refund{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fecc00;
    padding: 10px;
}

.refund h1{
    font-size: 50px;
}

.refund p{
    font-size: 20px;
    text-align: justify;
}

@media (max-width: 320px){
    .refund h1{
        font-size: 30px;
    }
}