.senior{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #01184e;
}

.senior p{
    text-align: justify;
    margin-right: 10px;
}

.senior h1{
    font-size: 50px;
    color: #fecc00;
}
.sen-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.sen-img img{
    height: 550px;
}

.sen-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sen-senior{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: justify;
    flex: 1;
}

.s-senior{
    background-color: #fecb00e1;
    padding: 8px;
    margin: 10px;
    border-radius: 10px;
    height: 300px;
    width: 440px;
    transition: transform 0.2s;
}

.s-senior:hover{
    transform: scale(1.03);
    background-color: #fecc00;
}

.s-senior h2{
    font-size: 25px;
}

@media screen and (max-width: 1140px) {
    .sen-img img{
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .s-senior{
        width: 340px;
    }
}

@media screen and (max-width: 700px) {
    .sen-senior{
        display: grid;
        grid-template-columns: 1fr;
    }
    .s-senior{
        height: 350px;
    }
}